import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's join Metro's 'Mybus' school transport scheme" description="Tetley's operate six buses as part of Metro's revolutionary 'Mybus' yellow bus scheme, providing dedicated school transport." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's join Metro's "Mybus" school transport scheme</h1>
              <p className="news-article-date">November 2012</p>
            </div>

            <div className="news-article-body">
              <p>Tetley's operate six buses as part of Metro's revolutionary 'Mybus' yellow bus scheme, providing dedicated school transport. </p>
              <p>Metro's fleet of custom vehicles provide dedicated home-to-school transport for over 9,000 pupils at 133 schools across West Yorkshire.</p>
              <p>Detailed information about Mybus, and how to apply for a pass, can be found on Metro's generationM website - the travel website for younger passengers.</p>
              <p>Visit <a href="http://www.generationm.co.uk">www.generationm.co.uk</a> to find out more.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
